import './LoadingView.css'

const LoadingView = () => {
  return (
    <div className="loading-container-outer">
    <div className="loading-container-inner">
    <img className="loading-image" src={require("../supporting_files/images/loading.svg").default} />
    </div>
    </div>
  )
}

export default LoadingView

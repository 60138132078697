import { useEffect, useRef, useState } from "react";
import Web3 from "web3/dist/web3.min";
import BigNumber from "bignumber.js";
import "./StakeView.css";
import LoadingView from "./LoadingView";

import {
  isTestMode,
  fluffyPuppyTokenAddress,
  fluffyPuppyTokenABI,
  stakedFluffyPuppyTokenAddress,
  stakedFluffyPuppyTokenABI,
} from "../models/config";

const StakeView = () => {
  const flufContract = useRef();
  const stakedFlufContract = useRef();

  const account = useRef();

  const [flufAmountToStake, setFlufAmountToStake] = useState("");
  const [flufAmountToStakeWithDecimals, setFlufAmountToStakeWithDecimals] =
    useState(BigNumber(0));

  const [flufAmountToUnstake, setFlufAmountToUnstake] = useState("");
  const [flufAmountToUnstakeWithDecimals, setFlufAmountToUnstakeWithDecimals] =
    useState(BigNumber(0));

  const [flufBalanceWithDecimals, setFlufBalanceWithDecimals] = useState(
    BigNumber(0)
  );
  const [sFlufBalanceWithDecimals, setSFlufBalanceWithDecimals] = useState(
    BigNumber(0)
  );

  const [currentFeePercentage, setCurrentFeePercentage] = useState(0);
  const [nextFeePercentage, setNextFeePercentage] = useState(0);
  const [reflectionFeePercentage, setReflectionFeePercentage] = useState(0);
  const [devFeePercentage, setDevFeePercentage] = useState(0);
  const [minAmountToStake, setMinAmountToStake] = useState(BigNumber(0));

  const [isApproved, setIsApproved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const numbersAndPeriodRegex = /^[0-9]*\.?[0-9]*$/;

  const web3 = new Web3(Web3.givenProvider || "http://localhost:9545");

  let didContractValuesLoad = false;

  const shouldDisableStake = () => {
    const isAmountToStakeGreaterThanTotal =
      flufAmountToStakeWithDecimals.isGreaterThan(flufBalanceWithDecimals);
    const isAmountToStakeLessThanMinAmount =
      flufAmountToStakeWithDecimals.isLessThan(minAmountToStake);

    return (
      isAmountToStakeGreaterThanTotal ||
      isAmountToStakeLessThanMinAmount ||
      !isApproved ||
      flufAmountToStake == ""
    );
  };

  const shouldDisableUnstake = () => {
    return (
      flufAmountToUnstakeWithDecimals == 0 ||
      flufAmountToUnstakeWithDecimals.isGreaterThan(sFlufBalanceWithDecimals) ||
      flufAmountToUnstake == ""
    );
  };

  const isIncorrectChain = () => {
    return (
      (isTestMode && window.ethereum?.networkVersion != 97) ||
      (!isTestMode && window.ethereum?.networkVersion != 56)
    );
  };

  const mainView = () => {
    return (
      <div>
        {isLoading ? <LoadingView /> : null}
        {headerView()}
        <div className="center-container-outer" id="main-container-outer">
          <div className="center-container-inner">
            {feeView()}
            <div className="main-container">
              {stakeView()}
              {unstakeView()}
            </div>
            {feeDisclaimerView()}
          </div>
        </div>
      </div>
    );
  };

  const headerView = () => {
    return (
      <div id="stake-header">
        <button
          className="custom-button"
          id="connect-wallet-button"
          onClick={connectWallet}
        >
          {connectWalletButtonText()}
        </button>

        <h1 id="title">Stake FLUF</h1>
      </div>
    );
  };

  const feeView = () => {
    return (
      <div style={{ marginBottom: "20px" }}>
        Current Staking/Unstaking Fee
        <br />
        <strong>
          <span>{currentFeePercentage}%</span>
          <span style={{ fontSize: 12 }}> (next: {nextFeePercentage}%)</span>
        </strong>
      </div>
    );
  };

  const stakeView = () => {
    return (
      <div className="stake-unstake-container-outer">
        <div className="stake-unstake-container-inner">
          <p>
            FLUF Balance
            <br />
            <strong>
              {flufBalanceWithDecimals.div(BigNumber("10e+17")).toFormat()} FLUF
            </strong>
          </p>
          <p>
            Minimum Amount to Stake
            <br />
            <strong>
              {minAmountToStake.div(BigNumber("10e+17")).toFormat()} FLUF
            </strong>
          </p>
          <div>
            <input
              type="text"
              name="stakeAmount"
              className="custom-text-input"
              value={flufAmountToStake}
              placeholder="Amount to Stake"
              onChange={didSetFlufAmountToStake}
            />
          </div>
          <div>
            <button
              className="custom-button"
              disabled={isApproved}
              onClick={approve}
            >
              Approve
            </button>
            <button
              className="custom-button"
              disabled={shouldDisableStake()}
              onClick={stake}
            >
              Stake
            </button>
          </div>
        </div>
      </div>
    );
  };

  const unstakeView = () => {
    return (
      <div className="stake-unstake-container-outer">
        <div className="stake-unstake-container-inner">
          <p>
            Staked FLUF Balance
            <br />
            <strong>
              {sFlufBalanceWithDecimals.div(BigNumber("10e+17")).toFormat()}{" "}
              SFLUF
            </strong>
          </p>
          <div>
            <input
              type="text"
              name="unstakeAmount"
              className="custom-text-input"
              value={flufAmountToUnstake}
              placeholder="Amount to Unstake"
              onChange={didSetFlufAmountToUnstake}
            />
          </div>
          <div>
            <button
              className="custom-button"
              disabled={shouldDisableUnstake()}
              onClick={unstake}
            >
              Unstake
            </button>
          </div>
        </div>
      </div>
    );
  };

  const feeDisclaimerView = () => {
    return (
      <p id="fee-disclaimer">
        This is a REFLECTION-BASED staking dapp, additional FLUF is not being
        "generated" or added into the system (for now).
        <br />
        The fee switches back
        and forth between {currentFeePercentage}% and {nextFeePercentage}%.
        <br />
        {reflectionFeePercentage}% of the fee reflects back to SFLUF holders
        (including the developer), {devFeePercentage}% goes to the developer
        wallet.
      </p>
    );
  };

  const connectWalletButtonText = () => {
    if (account.current != undefined) {
      const abbreviatedAccount =
        account.current?.substring(0, 6) +
        "..." +
        account.current?.substring(38, 42);
      return "Connected - " + abbreviatedAccount;
    } else {
      return "Connect Wallet";
    }
  };

  const changeChainView = () => {
    return (
      <div className="center-container-outer">
        <div className="center-container-inner">
          <button className="custom-button" onClick={changeChain}>
            Change Chain
          </button>
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.title = "Stake FLUF - Fluff Money";

    const bigNumberFormat = {
      prefix: "",
      decimalSeparator: ".",
      groupSeparator: ",",
      groupSize: 3,
      secondaryGroupSize: 0,
      fractionGroupSeparator: " ",
      fractionGroupSize: 0,
      suffix: "",
    };

    // Set the global formatting options
    BigNumber.config({ FORMAT: bigNumberFormat, EXPONENTIAL_AT: 999 });

    window.ethereum?.on("chainChanged", () => {
      window.location.reload();
    });
    window.ethereum?.on("accountsChanged", () => {
      window.location.reload();
    });

    let blockSubscription = web3.eth.subscribe(
      "newBlockHeaders",
      function (error, result) {
        if (error) {
          console.error(error);

          return;
        }

        updateContractValues();
      }
    );

    changeChain();

    const localFlufContract = new web3.eth.Contract(
      fluffyPuppyTokenABI,
      fluffyPuppyTokenAddress
    );

    flufContract.current = localFlufContract;

    const localStakedFlufContract = new web3.eth.Contract(
      stakedFluffyPuppyTokenABI,
      stakedFluffyPuppyTokenAddress
    );

    stakedFlufContract.current = localStakedFlufContract;

    connectWallet();

    return () => {
      blockSubscription.unsubscribe(function (error, success) {
        if (success) {
          console.log("Successfully unsubscribed from new block headers!");
        }
      });
    };
  }, []);

  async function changeChain() {
    const chainData = [
      {
        chainId: isTestMode ? "0x61" : "0x38",
        chainName: isTestMode
          ? "Binance Smart Chain Testnet"
          : "Binance Smart Chain",
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
        rpcUrls: [
          isTestMode
            ? "https://data-seed-prebsc-1-s1.binance.org:8545/"
            : "https://bsc-dataseed.binance.org/",
        ],
        blockExplorerUrls: [
          isTestMode ? "https://testnet.bscscan.com/" : "https://bscscan.com/",
        ],
      },
    ];

    const changeChainTransaction = await window.ethereum
      ?.request({ method: "wallet_addEthereumChain", params: chainData })
      .catch();
  }

  async function connectWallet() {
    try {
      const accounts = await web3.eth.requestAccounts();

      const localAccount = accounts[0];
      account.current = localAccount;

      updateContractValues();
    } catch (error) {
      console.log(error);
    }
  }

  function approve() {
    flufContract.current.methods
      .approve(stakedFluffyPuppyTokenAddress, BigNumber("10e+26").toString())
      .send({ from: account.current })
      .once("receipt", (receipt) => {});
  }

  function stake() {
    const amount = flufAmountToStakeWithDecimals.toString();

    stakedFlufContract.current.methods
      .flufToSFluf(amount)
      .send({ from: account.current })
      .once("receipt", (receipt) => {});
  }

  function unstake() {
    const amount = flufAmountToUnstakeWithDecimals.toString();

    stakedFlufContract.current.methods
      .sFlufToFluf(amount)
      .send({ from: account.current })
      .once("receipt", (receipt) => {});
  }

  function didSetFlufAmountToStake(event) {
    if (
      event.target.value == "" ||
      numbersAndPeriodRegex.test(event.target.value)
    ) {
      setFlufAmountToStake(event.target.value);
      setFlufAmountToStakeWithDecimals(
        BigNumber(event.target.value).times(BigNumber("10e+17"))
      );
    }
  }

  function didSetFlufAmountToUnstake(event) {
    if (
      event.target.value == "" ||
      numbersAndPeriodRegex.test(event.target.value)
    ) {
      setFlufAmountToUnstake(event.target.value);
      setFlufAmountToUnstakeWithDecimals(
        BigNumber(event.target.value).times(BigNumber("10e+17"))
      );
    }
  }

  function updateContractValues() {
    const prevDevFeePercentage = devFeePercentage;

    if (!didContractValuesLoad) {
      setIsLoading(true);
    }

    stakedFlufContract.current.methods
      .dappValues(account.current)
      .call((error, result) => {
        if (error == null && !isNaN(result.isApproved)) {
          setIsApproved(result.isApproved);
          setFlufBalanceWithDecimals(BigNumber(result.flufBalance));
          setSFlufBalanceWithDecimals(BigNumber(result.sFlufBalance));
          setCurrentFeePercentage(result.currentFeePercentage);
          setNextFeePercentage(result.nextFeePercentage);
          setReflectionFeePercentage(result.reflectionFeePercentage);
          setDevFeePercentage(result.devFeePercentage);
          setMinAmountToStake(BigNumber(result.minAmountToStake));
        }

        if (!didContractValuesLoad) {
          didContractValuesLoad = true;
          setIsLoading(false);
        }
      });
  }

  if (isIncorrectChain()) {
    return changeChainView();
  } else {
    return mainView();
  }
};

export default StakeView;

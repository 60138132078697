import { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./custom.css";
import "./style.css";
import "./HomeView.css";
import mainBg from "../supporting_files/images/main_bg.jpg";

const HomeView = () => {
  useEffect(() => {
    document.title = "Fluff Money";
  });

  return (
    <>
      <section
        id="slider"
        className="slider-element slider-parallax min-vh-60 min-vh-md-100 include-header"
      >
        <div
          className="slider-inner-element"
          style={{
            background: "url(" + mainBg + ") center center no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="vertical-middle slider-element-fade">
            <div className="container dark center">
              <div style={{ margin: "32px 0" }}>
                <div className="emphasis-title">
                  <p>
                    <img
                      id="main-logo"
                      src={
                        require("../supporting_files/images/logo.svg").default
                      }
                      alt="Logo"
                      height="100"
                    />
                  </p>
                  <h1>Fluff Money</h1>
                  <h4 id="slogan" className="font-body">
                    The Fluffiest Crypto Ecosystem
                  </h4>
                </div>
                <div id="main-buttons" style={{ margin: "auto" }}>
                  <a
                    href="/fluf/buy"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Buy FLUF
                  </a>
                  <a
                    href="/fluf/stake"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    Stake FLUF (BSC only)
                  </a>
                  <a
                    href="/fluf/charts"
                    className="button button-large button-light"
                    target="_blank"
                  >
                    FLUF Charts
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="content">
        <div className="content-wrap">
          <Container>
            <Row className="align-items-center">
              <Col md className="center">
                <div className="emphasis-title bottommargin-sm">
                  <h2>Fluff Money App</h2>
                </div>
                <p className="lead">
                  Play to Earn. Learn to Play.
                  <br />
                  Coming in 2024
                </p>
              </Col>
              <Col md className="center">
                <img
                  src={require("../supporting_files/images/app/screenshots_main.jpg")}
                  style={{ maxWidth: "300px" }}
                  alt="Fluff Money App"
                  data-animate="fadeInRight"
                />
              </Col>
            </Row>

            <div className="line"></div>

            <Row className="align-items-center">
              <Col md className="center">
                <h2>Staking</h2>
                <p className="lead">
                  Stake your FLUF to earn passive income.
                  <br />
                  <a href="/fluf/stake">
                    Check out the Staking Dapp! (for BSC only, SOL update coming
                    soon)
                  </a>
                </p>
              </Col>
              <Col md className="center">
                <img
                  className="rounded-corners"
                  src={require("../supporting_files/images/staking.jpg")}
                  alt="Staking"
                  data-animate="fadeInRight"
                />
              </Col>
            </Row>

            <div className="line"></div>
            <div className="clear"></div>

            <Row className="align-items-center">
              <Col md className="center">
                <h2>FluffDEX</h2>
                <p className="lead">
                  Amazingly low trading fees.
                  <br />
                  Coming soon.
                </p>
              </Col>
              <Col md className="center">
                <img
                  className="rounded-corners"
                  src={require("../supporting_files/images/fluffdex.jpg")}
                  alt="FluffDEX"
                />
              </Col>
            </Row>

            <div className="line"></div>
            <div className="clear"></div>
          </Container>

          <div id="roadmap" className="container clearfix">
            <div className="row clearfix">
              <div className="emphasis-title bottommargin-sm">
                <h2 className="center">Roadmap</h2>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 1 - 2022</h3>
                </div>
                <ul>
                  <li>Mobile App - Beta (came out in Jan 2023) ✓</li>
                  <li>Staking Dapp ✓</li>
                  <li>Social Marketing Campaigns ✓</li>
                  <li>NFT Drops ✓</li>
                </ul>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 2 - 2023</h3>
                </div>
                <ul>
                  <li>ETH version - (got created, the presale failed) ✓</li>
                  <li>Social Marketing Campaigns ✓</li>
                  <li>NFT Drops ✓</li>
                </ul>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 3 - 2024</h3>
                </div>
                <ul>
                  <li>
                    Services purchasable with FLUF tokens (postponed from 2023)
                  </li>
                  <li>FluffDEX (postponed from 2022)</li>
                  <li>
                    Mobile App - Release (postponed from 2022)
                  </li>
                  <li>Social Marketing Campaigns</li>
                  <li>NFT Drops</li>
                </ul>
              </div>
              <div className="col-lg-3 center">
                <div className="emphasis-title bottommargin-sm">
                  <h3>Phase 4 - 2025</h3>
                </div>
                <ul>
                  <li>NFT Marketplace (postponed from 2023)</li>
                  <li>Launchpad (postponed from 2023)</li>
                  <li>Social Marketing Campaigns</li>
                  <li>NFT Drops</li>
                </ul>
              </div>
            </div>

            <div className="line"></div>
            <div className="clear"></div>
          </div>

          <div id="faq" className="page-section container">
            <h2 className="center font-body bottommargin-lg">
              Frequently Asked Questions
            </h2>

            <div className="row topmargin-sm clearfix">
              <div className="col-lg-5 offset-lg-1 col-md-6 bottommargin-sm">
                <h4>How can I trade FLUF?</h4>
                <p>
                  <a href="/fluf/buy">Click here</a>, connect your wallet, set
                  slippage as low as you want (0.1% or 0.5% works), approve and
                  send your transaction. Simple as that!
                </p>
              </div>
              <div className="col-lg-5 col-md-6 bottommargin-sm">
                <h4>Does FLUF have reflections?</h4>
                <p>
                  Yes,{" "}
                  <a href="/fluf/stake">
                    if you stake FLUF (only for the BSC version as of now)
                  </a>
                  , it reflects a certain amount. Besides staking, there are
                  frequent giveaways that make being a FLUF holder beneficial.
                  Also, there will be services only available to FLUF holders in
                  the future.
                </p>
              </div>
              <div className="col-lg-5 offset-lg-1 col-md-6 bottommargin-sm">
                <h4>Does FLUF have taxes?</h4>
                <p>
                  FLUF itself doesn't have buy or sell taxes. Staking FLUF, on
                  the other hand, has a fee.{" "}
                  <a href="/fluf/stake">Staking and more info here.</a>
                </p>
              </div>
              <div className="col-lg-5 col-md-6 bottommargin-sm">
                <h4>Why is there a wallet holding 28.2% in the SOL version?</h4>
                <p>
                  <a
                    href="https://solscan.io/account/1nc1nerator11111111111111111111111111111111"
                    target="_blank"
                  >
                    That's the incinerator wallet.
                  </a>{" "}
                  The amount was sent to the wallet instead of being burnt the
                  "correct" way. Since the wallet has no owner, it stayed and
                  will stay that way. Oh well.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeView;

import React from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useEffect } from 'react'
import AppView from "./AppView"
import FlufView from "./FlufView"
import OMPTView from "./OMPTView"
import HeaderView from "./HeaderView"
import HomeView from "./HomeView"
import StakeView from "./StakeView"
import './style.css'
import './custom.css'
import 'bootstrap/dist/css/bootstrap.css'

const App = () => {
  useEffect(() => {
    // https://stackoverflow.com/a/38588927/3151675
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)

    if (window.location.hash && isChrome) {
      setTimeout(function () {
        var hash = window.location.hash
        window.location.hash = ""
        window.location.hash = hash
      }, 300)
    }

    if (window.location.hash === '') {
      window.scrollTo(0, 0)
    } else {
      let retries = 0

      const id = window.location.hash.replace("#", "")

      const scroll = () => {
        retries += 1

        if (retries > 500) {
          return
        }

        const element = document.getElementById(id)

        if (element) {
          setTimeout(() => {
            element.scrollIntoView()
          }, 0)
        } else {
          setTimeout(scroll, 100)
        }
      }

      scroll()
    }
  }, [])

  return (
    <BrowserRouter>
      <HeaderView />
      <div id="main-container">
        <Routes >
          <Route path="/" element={<HomeView />} exact />
          <Route path="/ompt" element={<OMPTView />} exact />
          <Route path="/fluf" element={<FlufView />} exact />
          <Route path="/fluf/stake" element={<StakeView />} exact />
          <Route path="/app" element={<AppView />} exact />
        </Routes>
      </div>
    </BrowserRouter>
  )
}

export default App
